.elevation-1 {
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4); */
    box-shadow:
        0 1px 3px rgba(0,0,0,0.12),
        0 1px 2px rgba(0,0,0,0.24);
}
.elevation-hover-1:hover {
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4); */
    box-shadow:
        0 1px 3px rgba(0,0,0,0.12),
        0 1px 2px rgba(0,0,0,0.24);
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
}

.elevation-2 {
    box-shadow:
        0 3px 6px rgba(0,0,0,0.16),
        0 3px 6px rgba(0,0,0,0.23);
}
.elevation-hover-2:hover {
    box-shadow:
        0 10px 20px rgba(0,0,0,0.19),
        0 6px 6px rgba(0,0,0,0.23);
}

.elevation-3 {
    box-shadow:
        0  9px 46px  8px rgba(0, 0, 0, 0.14),
        0 11px 15px -7px rgba(0, 0, 0, 0.12),
        0 24px 38px  3px rgba(0, 0, 0, 0.20);
}
.elevation-hover-3:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
