/* cabeçalho */
html {
    height: 100%;
    overflow-y: hidden;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: Roboto, sans-serif;
    scroll-behavior: smooth;
    font-size: 0.875em;
}

body {
    height: 100%;
    color: var(--font-color);
    background-color: var(--background-color);
}

h1,h2,h3 {
    font-weight: normal;
}

/* h1, h2, h3, h4 {
    font-family: Roboto,sans-serif;
} */

/* h1 {
    font-size: 6rem;
    font-weight: 300;
    letter-spacing: calc(-1.5 * 0.0625rem);
    line-height: 1;
} */

/* container */

.container {
    height: 100%;
}

/* container do body */
.body {
    display: flex;
    height: 100%;

    .body-content {
        box-sizing: border-box;
        display: inline-block;
        height: calc(100% - 52px);
        overflow-y: auto;
        flex: 1 1 auto;

        & > * {
            margin: 1em;
        }

        @media screen and (max-width: 480px) {
            left: 0;
            width: 100%;
        }
    }
}

/* icones */

.icon {
    display: inline-block;
    margin: 0 0.15em;
    vertical-align: middle;

    &.icon-color-white {
        fill: var(--white);
    }

    &.icon-color-red {
        fill: var(--red500);
    }
}

.clickable {
    cursor: pointer;
}

/* tabela */

.card {
    background-color: var(--background-color);
    border: solid 1px var(--border-color);
    border-radius: var(--border-radius);
    transition: box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);
}

.shadow {
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 3px 16px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22);
}

/* .card:hover {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 3px 16px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22);
} */

.table {
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    & > .row {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        transition: 0.3s;

        &:not(:last-child) {
            border-bottom: solid 1px #03030330;
        }

        &:not(.header) {
            cursor: pointer;

            &:hover {
                background-color: #dddddd8a;
            }
        }

        & + .selected {
            background-color: #dddddd8a;
        }

        & > .cell {
            flex: 1 1 auto;
            padding: 0.5em;
            position: relative;
            /* word-break: break-all; */
        }

        & > * {
            align-items: center;
        }
    }

    & > .header {
        background-color: #333;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        color: #FFFFFF;
        flex: 0 0 25%;
        font-weight: bold;
    }

    .cell {
        display: flex;

        & > .cell-header {
            flex: 1 1 auto;
        }
    }
}

.central {
    text-align: justify;
    width: max(40%, 480px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    padding: 2em;
    box-sizing: border-box;

    img {
        width: 100%;
        margin-bottom: 3em;
        box-sizing: border-box;
        display: block;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        margin-left: 0;
    }
}

:host {
    display: block;
}

.alert {
    border: solid #03030330 1px;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1em;
    margin-top: 0.5em;

    a {
        font-weight: bold;
    }

    &.warning {
        background: var(--yellow200) !important;
        border-color: var(--yellow500) !important;
        color: var(--yellow800) !important;
        fill: var(--yellow800) !important;
    }

    &.danger {
        background: var(--red200) !important;
        border-color: var(--red400) !important;
        color: var(--red700) !important;
        fill: var(--red700) !important;
    }

    &.success {
        background: var(--green200) !important;
        border-color: var(--green400) !important;
        color: var(--green700) !important;
        fill: var(--green700) !important;
    }
}

input.danger,
textarea.danger {
    background: var(--red100) !important;
    border-color: var(--red400) !important;
    color: var(--red700) !important;
    fill: var(--red700) !important;

    &::-webkit-input-placeholder {
        color: var(--red300) !important;
        fill: var(--red300) !important;
    }
}

.danger label {
    color: var(--red700) !important;
    fill: var(--red700) !important;
}

.bordered {
    border: solid var(--light-grayish-blue) 1px;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1em;
}

.badge {
    background-color: var(--purple);
    border-radius: var(--border-radius);
    color: var(--white);
    display: inline-block;
    padding: 0.3em 0.5em;
}

/* tamanhos e espaçamentos */

.size-1 {
    height: auto;
    width: 1em;
}

.size-2 {
    height: auto;
    width: 1.5em;
}

.size-3 {
    height: auto;
    width: 2em;
}

.size-4 {
    height: auto;
    width: 2.5em;
}

.w-30 {
    height: auto;
    width: 30%;
}

.mb {
    margin-bottom: 1em;
}

.mt {
    margin-top: 1em;
}

.ml {
    margin-left: 1em;
}

.mr {
    margin-right: 1em;
}

.my {
    margin: 1em 0;
}

.mx {
    margin: 0 1em;
}

.flex-size-2 {
    flex-basis: 100% !important;
    flex-grow: 1 !important;
}

.flex-size-2 {
    flex-basis: 200% !important;
    flex-grow: 2 !important;
}

.flex-size-3 {
    flex-basis: 300% !important;
    flex-grow: 3 !important;
}

/* tooltips */

[tooltip] {
    position: relative;

    &::before,
    &::after {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -6px;
        transition: opacity 0.25s ease;
        z-index: 103;
    }

    &::before {
        content: "";
        transform: translateX(-50%);
        border-width: 4px 6px 0 6px;
        border-style: solid;
        border-color: var(--black) transparent transparent transparent;
    }

    &::after {
        font-family: Roboto, sans-serif;
        content: attr(tooltip);
        transform: translateX(-50%) translateY(-100%);
        background: var(--black);
        font-weight: normal;
        text-align: center;
        color: var(--white);
        padding: 6px 3px;
        font-size: 12px;
        min-width: 90px;
        border-radius: var(--border-radius);
        pointer-events: none;
    }

    &:hover::before,
    &:hover::after {
        opacity: 1;
        transition-delay: 0.75s;
    }
}

[tooltip-down] {
    position: relative;

    &::after,
    &::before {
        opacity: 0;
        position: absolute;
        left: 50%;
        transition: opacity 0.25s ease;
    }

    &::before {
        font-family: Roboto, sans-serif;
        content: attr(tooltip-down);
        transform: translateX(-50%) translateY(-100%);
        background: var(--black);
        font-weight: normal;
        text-align: center;
        color: var(--white);
        padding: 6px 3px;
        font-size: 12px;
        padding: 0 10px;
        border-radius: var(--border-radius);
        pointer-events: none;
        top: 49px;
    }

    &::after {
        content: "";
        transform: translateX(-50%);
        border-width: 0 6px 4px 6px;
        border-style: solid;
        border-color: transparent transparent var(--black) transparent;
        z-index: 100;
        top: 31px;
    }

    &:hover::before,
    &:hover::after {
        opacity: 1;
        transition-delay: 0.75s;
    }
}
