:root{
    --white: #FFFFFF;
    --black: #000000;

    --blue200: #9BB8DA;
    --blue300: #80bdff;
    --blue400: #2575D0;
    --blue500: #1565c0;
    --blue600: #0555B0;
    --blue700: #0D47A1;

    --green200: #C8E6C9;
    --green400: #4CAF50;
    --green500: #339933;
    --green600: #2C8F30;
    --green700: #1B5E20;

    --red100: #FFEBEE;
    --red200: #FFCDD2;
    --red300: #E68A8A;
    --red400: #E57373;
    --red500: #F44336;
    --red600: #D42316;
    --red700: #B71C1C;

    --yellow200: #FFF9C4;
    --yellow500: #fff176;
    --yellow600: #FFFF00;
    --yellow700: #F0F000;
    --yellow800: #856404;

    --gray100: #EFEFEF;
    --gray200: #D2D8DD;
    --gray300: #B5B5B5;
    --gray400: #9E9E9E;
    --gray500: #666666;
    --gray600: #333333;
    --gray700: #222222;

    --purple: #6A1B9A;
    --magenta: #9C27B0;
    --cyan: #00BCD4;
    --dark-cyan: #517579;
    --light-grayish-blue:#ced4da;
    --orange: #FF5722;

    --font-color: var(--black);
    --background-color: var(--white);

    --border-color: #03030330;

    --inverted-font-color: var(--white);
    --inverted-background-color: var(--black);

    --border-radius: 5px;
}
