.form-section {
    margin-bottom: 1em;
    margin-top: 0.5em;

    label {
        color: var(--gray700);
        display: block;
        margin-bottom: 0.5em;
    }

    input,
    select,
    textarea {
        color: var(--black);
        background: var(--white);
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 0.35em;
        border-radius: var(--border-radius);
        border: solid 1px var(--light-grayish-blue);
        line-height: 1.5;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        resize: none;

        &:focus {
            border-color: var(--blue300);
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
            outline: none;
        }
    }

    input[type="checkbox"] {
        display: inline;
        width: auto;
        margin: auto 1rem;
    }

    small {
        color: var(--dark-cyan);
    }
}

::placeholder,
::-webkit-input-placeholder, /* Chrome/Opera/Safari */
::-moz-placeholder, /* Firefox 19+ */
:-ms-input-placeholder, /* IE 10+ */
:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: var(--gray200);
}

.rotated {
    transform: rotate(90deg);
}

button {
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
    outline: none;
}

.btn {
    border-radius: var(--border-radius);
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin: 0.5rem 0.5rem 0.5rem 0;
    transition: box-shadow 0.3s ease,background 0.3s ease;

    &.btn-primary,
    &.btn-success,
    &.btn-danger,
    &.btn-warning,
    &.btn-outline {
        color: var(--white);
    }


    &:disabled {
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    &.btn-primary {
        background: var(--blue400);

        &:not(:disabled):hover {
            background: var(--blue600);
        }
    }

    &.btn-success {
        background: var(--green400);

        &:not(:disabled):hover {
            background: var(--green600);
        }
    }

    &.btn-danger {
        background: var(--red500);

        &:not(:disabled):hover {
            background: var(--red600);
        }
    }

    &.btn-warning {
        background: var(--yellow600);
        color: var(--black);

        &:not(:disabled):hover {
            background: var(--yellow700);
        }
    }

    &.btn-outline {
        border: solid var(--light-grayish-blue) 1px;
        background: transparent;
        color: var(--blue700);
        box-shadow: none;

        &:not(:disabled):hover {
            background-color: rgba(0, 0, 0, 0.075);
            box-shadow: none;
        }
    }
}

.btn-block {
    width: 100%!important;
    text-align: center;
}

.outline-danger {
    border: solid var(--red400) 1px;
    color: var(--red700);
}

.fab {
    position: fixed;
    font-size: 1.5em;
    padding: 0 1em;
    right: 2em;
    bottom: 1.25em;
    border-radius: var(--border-radius);
    height: 2em;
    background-color: var(--blue400);
    color: var(--white);
    transition: box-shadow 0.3s ease;
    animation: droplet-inside linear 4s infinite;
    box-shadow:
        0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.20);

    & > .icon {
        vertical-align: sub;
    }

    &:hover {
        box-shadow:
            0 7px 15px 2px rgba(0, 0, 0, 0.20),
            0 2px 16px 2px rgba(0, 0, 0, 0.18),
            0 4px 8px 0px rgba(0, 0, 0, 0.26)
    }
}

.chip {
    border-radius: var(--border-radius);
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 0.5em 1em;
    transition: color 0.3s ease, border 0.3s ease, background 0.3s ease;
    border: solid transparent 1px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }
}

.chip-outline {
    border-color: var(--light-grayish-blue);
    color: var(--blue700);
    background: transparent;
}

.chip-selected {
    border-color: var(--blue400);
    background: var(--blue400);
    color: var(--white);
}

.checkbox {
    border: solid 2px #03030330;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 20px;
    margin: auto;
    position: relative;
    transition: 0.3s;
    width: 20px;

    &.selected {
        border-color: var(--blue500);

        &::after {
            background-color: var(--blue500);
            border-radius: 50%;
            content: '';
            height: 12px;
            margin-left: 2px;
            margin-top: 2px;
            position: absolute;
            animation: sheen ease 0.20s both;
            /* transition: width,height 0.25s ease; */
            width: 12px;
            top: 0;
            left: 0;
        }
    }
}


.password-container {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 1em;
    top: 2em;
}

@keyframes sheen {
    0% {
        margin-left: 8px;
        width: 0;
        margin-top: 8px;
        height: 0;
    }
    100% {
        margin-left: 2px;
        width: 12px;
        margin-top: 2px;
        height: 12px;
    }
}

@keyframes droplet-inside {
    0% {
        box-shadow:
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0),
            inset 60px 0px 0px 0px rgba(255,255,255, 0.10),
            inset -60px 0px 0px 0px rgba(255,255,255, 0.10);
    }
    60% {
        box-shadow:
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0),
            inset 0px 0px 0px 0px rgba(255,255,255, 0.10),
            inset 0px 0px 0px 0px rgba(255,255,255, 0.10);
    }
    61% {
        box-shadow:
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0),
            inset 0px 0px 10px 0px rgba(255,255,255, 0),
            inset 0px 0px 10px 0px rgba(255,255,255, 0);
    }
    62% {
        box-shadow:
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0),
            inset 60px 0px 0px 0px rgba(255,255,255, 0),
            inset -60px 0px 0px 0px rgba(255,255,255, 0);
    }
    100% {
        box-shadow:
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0),
            inset 60px 0px 0px 0px rgba(255,255,255, 0.10),
            inset -60px 0px 0px 0px rgba(255,255,255, 0.10);
    }
}
