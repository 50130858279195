.overlay {
    visibility: hidden;
    position: fixed;
    z-index: 101;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000BB;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

.modal {
    box-sizing: border-box;
    position: absolute;
    z-index: 102;
    left: 0;
    right: 0;
    transform: translate(0, 0);
    display: flex;
    flex-direction: column;
    min-width: 8em;
    max-width: 75%;
    max-height: 90%;
    width: 100%;
    background-color: var(--white);
    margin: 1.5em auto;
    padding: 3em;
    border-radius: var(--border-radius);
    overflow-y: auto;
}

.modal .full-img {
    max-width: 100%;
    display: block;
    margin: auto;
}

.modal .inline-img {
    display: inline-block;
    vertical-align: middle;
    border: solid 1px #999;
    border-radius: 0.5rem;
}

.modal a {
    color: var(--blue500);
}


.modal-content {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: justify;
    line-height: 1.5;
    overflow-y: auto;
}

.modal-content .row {
    display: flex;
    align-items: center;
}

.modal-content .row > *:not(:last-child) {
    margin-right: 1em;
}

.modal-header {
    flex: 1 1 10%;
    padding-bottom: 1em;
    font-size: 1.5em;
    font-family: Roboto, sans-serif;

}

.modal-footer {
    flex: 1 1 10%;
    padding-top: 1em;
    text-align: right;
}


.visible {
    visibility: visible;
    opacity: 1;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #00000033;
}